import { systemConstants } from "@shared/constants/systemConstants";

import { QueryAction, QueryStatus } from "@app/types/index.ts";

export enum ActionItemTypeOption {
  CONVERSATION = "CONVERSATION",
  SMARTFORM = "SMARTFORM",
  WEBSHEET = "WEBSHEET"
}

export type ActionItemTypeUserOption = string[] | string;

export interface ActionItemTypeAction {
  action: QueryAction;
  availableTo: ActionItemTypeUserOption;
}

export interface ActionItemTypeMenu {
  name: string;
  actions: ActionItemTypeAction[];
}

export interface ActionItemTypeValidation {
  name: string;
  value: number | string | string[];
}

export interface ActionItemTypeField {
  key: string;
  type: string;
  required: boolean;
  updatableBy: ActionItemTypeUserOption;
  creatableBy: ActionItemTypeUserOption;
  availableTo: ActionItemTypeUserOption;
  rename?: boolean;
  validations?: ActionItemTypeValidation[];
  defaultValue: string;
  rules?: [
    {
      key: string;
      type: string;
      enableMinDate?: boolean;
    }
  ];
}

export interface ActionItemTypeStatusInfo {
  icon: string;
  label: string;
  state: string;
}

export interface ActionItemTypeDisableFieldUpdate {
  fieldKeys: string | string[];
  conditions: string | string[];
}

export interface ActionItemTypeTransition {
  from: string;
  to: string;
  on: string;
  flag?: string;
}

export interface WorkflowStep {
  name?: string;
  queryStatus: string;
  actions: ActionProps[];
  operations: Operation[];
}

export const UPDATE_WORKFLOW_STEP = "updateWorkflowStep" as const;

type OperationType =
  | typeof UPDATE_WORKFLOW_STEP
  | "sendEmail"
  | "sendPayloadToExternal"
  | "updateQueryStatus";
export interface Operation {
  type: OperationType;
  value?: string;
  emailType?: string;
}

export interface ActionProps {
  i18nActionKey: string;
  key: string;
  availableTo: string[];
  visibleMenus: string[];
  name?: string;
  operations: Operation[];
}

export interface ActionItemTypeWorkflow {
  initialStep: string;
  stepsOrder: string[];
  steps: {
    [step: string]: WorkflowStep;
  };
}

export interface WorkflowConfig extends ActionItemTypeWorkflow {}
export interface ActionItemTypeConfiguration {
  key: string;
  type: ActionItemTypeOption;
  menus: ActionItemTypeMenu[];
  description?: string;
  fields: ActionItemTypeField[];
  copyFrom?: string[];
  creators: string;
  statuses: {
    [status: string]: ActionItemTypeStatusInfo;
  };
  updaters: ActionItemTypeUserOption;
  createFrom: string[];
  sendEmails?: {
    onResponse: boolean;
  };
  transitions: ActionItemTypeTransition[];
  initialStatus: QueryStatus;
  disableFieldUpdates: ActionItemTypeDisableFieldUpdate[];
  projectYear?: string;
  workflow?: ActionItemTypeWorkflow;
  useCustomName?: boolean;
  isUserManaged?: boolean;
}

export interface ActionItemType {
  id: number;
  configuration: ActionItemTypeConfiguration;
  name: string;
  description?: string;
  type?: string;
  createdBy?: string;
  updatedBy?: string;
  engagementType?: string;
  year?: string | null;
  engagementTypeId?: number;
}

export interface AITFormatFormConfigRule {
  key: string;
  type: string;
  validationKey: string;
  defaultValue?: string | number | string[];
  min?: number;
  max?: number;
  enableMinDate?: boolean;
  required?: boolean;
}

export interface AITFormatFormConfig {
  key: string;
  type: string;
  section: string;
  rules: AITFormatFormConfigRule[];
  condition?: string;
  locked?: string[];
  active?: boolean;
  options?: string[];
  availableTo?: string | string[];
  creatableBy?: string | string[];
  updatableBy?: string | string[];
}

export interface AITPermissionConfig {
  key: string;
  defaultSelection: string[];
  availableTo?: string[];
  type: string;
}

export interface AITFormatConfig {
  type: string;
  formsConfig: AITFormatFormConfig[];
  permissions: AITPermissionConfig[];
  availableTo: string[];
  groups: {
    fields: {
      availableTo: string[];
      creatableBy: string[];
      updatableBy: string[];
    };
  };
}

export interface AITEngagementType {
  id: number;
  name: string;
  configuration: {
    milestones?: {
      labels?: {
        enabled: boolean;
        names: { key: string }[];
      };
    };
  };
}

export interface AITFormOptions {
  engagementTypes: AITEngagementType[];
  createFrom: string[];
  documentExtensions: string[];
  formats: AITFormatConfig[];
}

export const enum CrudFormMode {
  CREATE = "CREATE",
  READ = "READ",
  UPDATE = "UPDATE"
}

export const StepStatusList = [
  systemConstants.project.queries.status.closed,
  systemConstants.project.queries.status.open
];

export type ItemList = {
  name: string;
  value: string;
};
