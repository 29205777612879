import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";

import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  AITFormOptions,
  ActionItemType,
  CrudFormMode
} from "@app/types/ActionItemType.ts";

import { Button } from "@atoms/Button/Button.tsx";
import { ButtonVariant } from "@atoms/Button/index.ts";
import Icon from "@atoms/Icon/Icon.tsx";
import { Pill, PillSize, PillVariant } from "@atoms/Pill/Pill.tsx";

import BoxTemplate from "@templates/BoxTemplate/BoxTemplate.jsx";

import { Inline, Stack } from "@components/fermions/index.tsx";

import WorkflowBuilder from "./WorkflowBuilder/WorkflowBuilder.tsx";
import { ActionNode } from "./WorkflowCanvas/ActionNode.tsx";
import { StepNode } from "./WorkflowCanvas/StepNode.tsx";
import WorkflowCanvas from "./WorkflowCanvas/WorkflowCanvas.tsx";
import { WorkflowCanvasProvider } from "./WorkflowCanvas/WorkflowCanvasContext.tsx";

interface ActionItemTypeFormWorkflowProps {
  actionItemType: ActionItemType;
  formOptions: AITFormOptions;
  crudFormMode: CrudFormMode;
}

const ActionItemTypeFormWorkflow = (props: ActionItemTypeFormWorkflowProps) => {
  const { actionItemType, crudFormMode = CrudFormMode.READ } = props;
  const { setValue } = useFormContext();
  const { t } = useTranslation();
  const workflowBtnLabel =
    CrudFormMode.UPDATE === crudFormMode
      ? t("admin:ui.requestTypes.workflow.configure.edit.cta")
      : t("admin:ui.requestTypes.workflow.configure.view.cta");
  const [pendingWorkflow, setPendingWorkflow] = useState(
    actionItemType?.configuration?.workflow
  );
  const dialogRef = useRef<HTMLDialogElement>(null);
  const [loadWorkflowBuilder, setLoadWorkflowBuilder] = useState(false);
  const workflow = useMemo(() => {
    return actionItemType?.configuration?.workflow;
  }, [actionItemType?.configuration?.workflow]);

  const hasPendingChanges = useMemo(() => {
    if (!workflow || !pendingWorkflow) {
      return false;
    }
    return JSON.stringify(workflow) !== JSON.stringify(pendingWorkflow);
  }, [pendingWorkflow, workflow]);

  useEffect(() => {
    setPendingWorkflow(actionItemType?.configuration?.workflow);
  }, [actionItemType?.configuration?.workflow]);

  useEffect(() => {
    if (workflow) {
      setValue("workflow", workflow);
    }
  }, [setValue, workflow]);

  const onWorkflowUpdate = useCallback(
    updatedConfig => {
      setPendingWorkflow(updatedConfig);
      setValue("workflow", updatedConfig);
      dialogRef?.current?.close();
      setLoadWorkflowBuilder(false);
    },
    [dialogRef, setValue]
  );

  const onWorkflowCloseDialog = useCallback(() => {
    dialogRef?.current?.close();
    setLoadWorkflowBuilder(false);
  }, [dialogRef]);

  const onWorkflowDiscardChanges = useCallback(() => {
    setPendingWorkflow(workflow);
    setValue("workflow", workflow);
  }, [workflow, setValue]);

  const openWorkflowBuilder = () => {
    dialogRef.current?.showModal();
    setLoadWorkflowBuilder(true);
  };

  const renderStatus = useCallback(() => {
    if (!hasPendingChanges) {
      return null;
    }
    return (
      <>
        <Button
          label={t("admin:ui.requestTypes.workflow.discard.label")}
          variant={ButtonVariant.TEXT}
          onClick={onWorkflowDiscardChanges}
        />
        <Pill
          label={t("admin:ui.requestTypes.workflow.status.unchanged.label")}
          variant={PillVariant.ACCENT}
          size={PillSize.SMALL}
        />
      </>
    );
  }, [hasPendingChanges, onWorkflowDiscardChanges, t]);

  const Legend = () => {
    return (
      <Stack alignment="top-right" gap="150">
        <Inline
          gap="050"
          alignment="center"
          className="ait-form-workflow__legend-title"
        >
          <Icon name="info" size="s" />
        </Inline>
        <Inline
          gap="100"
          alignment="center"
          className="ait-form-workflow__legend-key"
        >
          <StepNode
            node={{
              id: "1",
              isHovered: false,
              displayLabel: () => {
                return t("admin:ui.workflow.legend.step.label");
              }
            }}
            mode="legend"
          />
          <ActionNode
            node={{
              id: "2",
              isHovered: false,
              displayLabel: () => {
                return t("admin:ui.workflow.legend.actionWithRules.label");
              }
            }}
            mode="legend"
          />
          <ActionNode
            node={{
              id: "3",
              isHovered: false,
              displayLabel: () => {
                return t("admin:ui.workflow.legend.actionWithoutRules.label");
              }
            }}
            mode="legend"
            withRules={false}
          />
        </Inline>
      </Stack>
    );
  };

  const preventClosingUsingEscapeKey = (e: React.KeyboardEvent) => {
    if (e.key === "Escape") {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  if (!workflow) {
    return <></>;
  }

  return (
    <BoxTemplate
      title={t("admin:ui.requestTypes.workflow.title")}
      subtext={t("admin:ui.requestTypes.workflow.subtitle")}
      action={<Legend />}
    >
      {pendingWorkflow && (
        <Stack
          gap="300"
          alignment="left"
          padding="200"
          className="ait-form-workflow__container"
        >
          <WorkflowCanvas
            workflow={pendingWorkflow}
            crudFormMode={CrudFormMode.READ}
            actionItemType={actionItemType}
            canSelect={false}
          />

          <>
            <Inline gap="200" alignment="left">
              <Button
                label={workflowBtnLabel}
                iconName="chevron_right"
                iconOnRight={true}
                onClick={openWorkflowBuilder}
              />
              {renderStatus()}
            </Inline>

            {/* best is if this is all inside a component that can be created/destroyed if its not visible as the state inside the dialog doesn't need to be retained */}
            <dialog
              ref={dialogRef}
              className="ait-form-workflow__dialog"
              onKeyDown={preventClosingUsingEscapeKey}
            >
              {loadWorkflowBuilder && (
                <WorkflowCanvasProvider>
                  <WorkflowBuilder
                    onWorkflowCloseDialog={onWorkflowCloseDialog}
                    onWorkflowUpdate={onWorkflowUpdate}
                    workflow={pendingWorkflow}
                    crudFormMode={crudFormMode}
                    actionItemType={actionItemType}
                  />
                </WorkflowCanvasProvider>
              )}
            </dialog>
          </>
        </Stack>
      )}
    </BoxTemplate>
  );
};

export default ActionItemTypeFormWorkflow;
